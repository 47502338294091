import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display:flex; 
  /* margin-top:10px;  */
  justify-content:flex-start; 
  align-items: center;
  height:100%;
  width:100%;
  font-weight:700px;
  font-size:43px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999999;
  color: #fff;
  background: rgba(0,0,0,.9);
`;

export const Box = styled.div`
  width:1000px;
  align-self:center;
`;

export const BoxImg = styled.div`
  margin-right: 100px;
  margin-left: 100px;
`;

export const StyledLink = styled.a`
  display:block;
  align-self: center;
  text-decoration: none;
  color:#ffffff;
`;

export const StyledImg = styled.img`
  width: 300px;
  height: auto;
`;